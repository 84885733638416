<template>
  <!--  This fix is required for case when user changes his tenant on this page.  -->
  <!--  The page rerender isn't triggered as we stay on the same page (this is the default page) and we need to handle this using key.  -->
  <div :key="operator.selectedTenant ? operator.selectedTenant.uid : ''" class="dashboard">
    <dashboard-chart
      v-for="chart in charts"
      :key="chart.id"
      v-bind="chart"
      @select="(data) => saveData(chart.id, data)"
    />
  </div>
</template>
<script>
import operator from '@/application/operator';
import { storeGetters, storeMutations } from '@/modules/dashboard/store';
import Urls from '@/application/urls';
import http from '@/lib/http';
import DashboardChart from './dashboard-chart.vue';

export default {
  components: {
    DashboardChart,
  },
  data() {
    return {
      operator,
    };
  },
  computed: {
    ...storeGetters,
    charts() {
      return this.dashboardsData.map(({ defaultParams }) => {
        const {
          dataStep, chartType, id, borderColor, backgroundColor,
        } = defaultParams;
        const getQueryParam = (paramName) => this.$route.query[`${id}-${paramName}`];

        return {
          ...defaultParams,
          chartStyles: {
            borderColor,
            backgroundColor,
          },
          dataStep: getQueryParam('dataStep') || dataStep,
          chartType: getQueryParam('chartType') || chartType,
        };
      });
    },
  },
  watch: {
    'operator.selectedTenant': {
      immediate: true,
      async handler() {
        storeMutations.clear();
        await this.fetchDashboards();
      },
    },
  },
  methods: {
    async fetchDashboards() {
      try {
        const { data } = await http.get(Urls.DASHBOARDS);
        storeMutations.setDashboardsData(data);
      } catch {}
    },
    saveData(id, data) {
      const updatedParams = Object.fromEntries(Object.entries(data).map(([k, v]) => [`${id}-${k}`, v]));
      this.$router
        .replace({
          path: this.$route.path,
          params: this.$route.params,
          query: { ...this.$route.query, ...updatedParams },
        })
        .catch(() => {});
    },
  },
};
</script>
