import Vue from 'vue';

const defaultStoreData = {
  dashboardsData: [],
};

export const store = Vue.observable({ ...defaultStoreData });

export const storeGetters = {
  dashboardsData: () => store.dashboardsData,
};

export const storeMutations = {
  setDashboardsData(value) {
    store.dashboardsData = value;
  },
  clear() {
    store.dashboardsData = [];
  },
};
