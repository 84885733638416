<template>
  <div class="dashboard-chart ui-panel">
    <div class="dashboard-chart__menu">
      <h1 class="dashboard-chart__header" v-text="$translate(label)"></h1>
      <div class="dashboard-chart__options">
        <select
          v-if="Object.keys(dataSteps).length > 0"
          :id="`${id}-data-step`"
          :value="dataStep"
          class="ui-select dashboard-chart__select"
          @change="selectedDataStepChange"
        >
          <option
            v-for="[key, value] in Object.entries(dataSteps)"
            :key="key"
            :value="value"
            v-text="$translate(key)"
          ></option>
        </select>
        <select
          v-if="Object.keys(chartTypes).length > 0"
          :id="`${id}-chart-type`"
          :value="chartType"
          class="ui-select dashboard-chart__select"
          @change="selectedChartTypeChange"
        >
          <option
            v-for="[key, value] in Object.entries(chartTypes)"
            :key="key"
            :value="value"
            v-text="$translate(key)"
          ></option>
        </select>
      </div>
    </div>
    <canvas :id="id"></canvas>
  </div>
</template>
<script>
import ChartJS from 'chart.js';
import { DateFormat } from 'redge-media-web-utils';
import http from '@/lib/http';
import Urls from '@/application/urls';

export const DATA_STEPS = {
  DAYS_1: 'HOUR_24',
  DAYS_7: 'DAY_7',
  DAYS_30: 'DAY_30',
};

export const CHART_TYPES = {
  LINE: 'line',
  BAR: 'bar',
};

const DEFAULT_CHART_OPTIONS = {
  legend: {
    display: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  animation: {
    duration: 0,
  },
  scales: {
    yAxes: [
      {
        display: true,
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    dataType: {
      type: String,
      required: true,
    },
    dataStep: {
      type: String,
    },
    chartType: {
      type: String,
    },
    chartStyles: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chart: undefined,
      chartTypes: CHART_TYPES,
      chartValues: [],
      dataSteps: DATA_STEPS,
    };
  },
  computed: {
    selectedDataStepStep() {
      return this.dataStep.split('_')[0];
    },
    selectedDataStepCount() {
      return this.dataStep.split('_')[1];
    },
    chartData() {
      const chartValues = [...this.chartValues];
      const dateLabelFormat = this.selectedDataStepStep === 'HOUR' ? DateFormat.HOUR_MINUTE_FORMAT : DateFormat.DAY_MONTH_FORMAT;
      const labels = chartValues.map(({ date }) => this.$dateFormat(date, dateLabelFormat));
      const datasets = [
        {
          ...this.chartStyles,
          fill: this.chartType !== CHART_TYPES.LINE,
          data: chartValues.map(({ values }) => values[0].value),
        },
      ];

      return { labels, datasets };
    },
  },
  watch: {
    async dataStep(prev, curr) {
      const isEqual = Object.keys(prev).every((k) => curr[k] === prev[k]);

      if (isEqual) {
        return;
      }

      await this.updateChartData();
    },
    chartType() {
      this.chart.destroy();
      this.renderChart();
    },
  },
  beforeDestroy() {
    this.chart.destroy();
  },
  async created() {
    await this.fetchChartValues();
    this.renderChart();
  },
  methods: {
    emitData(data) {
      this.$emit('select', { ...data });
    },
    selectedDataStepChange({ target: { value } }) {
      this.emitData({ dataStep: value });
    },
    selectedChartTypeChange({ target: { value } }) {
      this.emitData({ chartType: value });
    },
    async updateChartData() {
      await this.fetchChartValues();
      this.chart.data.labels = this.chartData.labels;
      this.chart.data.datasets = this.chartData.datasets;
      this.chart.update();
    },
    async fetchChartValues() {
      const {
        data: { values },
      } = await http.get(Urls.DASHBOARDS_NAME(this.dataType), {
        params: {
          step: this.selectedDataStepStep,
          count: this.selectedDataStepCount,
        },
      });
      this.chartValues = values;
    },
    renderChart() {
      this.chart = new ChartJS(this.id, {
        type: this.chartType ? this.chartType : Object.values(CHART_TYPES)[0],
        data: { ...this.chartData },
        options: DEFAULT_CHART_OPTIONS,
      });
    },
  },
};
</script>
